const invalidProp = function(args, details, deprecated) {
  let props = args[0]
  let propName = args[1]
  let componentName = args[2]
  deprecated = deprecated || true

  if (props[propName] !== undefined) {
    return new Error(
      `Invalid prop \`${propName}\` supplied to \`${componentName}\`.${
        details ? ` ${details}` : ''
      }${deprecated ? ` Will be removed in Typor v3.0.` : ''}`
    )
  }
}

export default invalidProp
