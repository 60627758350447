import React, { Component } from 'react'
import PropTypes from 'prop-types'
import TypeTester from './TypeTester'
import log from './utils/log'

// Control components
import DefaultSlider from './controls/Slider'

const supportedProperties = {
  fontFamily: {
    label: 'Typeface',
    type: 'select',
    defaultValue: 'Work Sans',
    options: [
      'Work Sans',
      { label: 'Avenir Next', value: 'AvenirNext-Bold' },
      'Georgia',
    ],
  },
  fontSize: {
    label: 'Font Size',
    type: 'range',
    defaultValue: 50,
    min: TypeTester.defaultProps.minFontSize,
    max: TypeTester.defaultProps.maxFontSize,
    units: 'px',
  },
  lineHeight: {
    label: 'Line Height',
    type: 'range',
    defaultValue: 1.5,
    min: 1,
    max: 2,
    step: 0.1,
  },
  letterSpacing: {
    label: 'Tracking',
    type: 'range',
    defaultValue: 0,
    min: -0.2,
    max: 0.2,
    step: 0.01,
    units: 'em', // ems work, but mess with scale sizing
  },
}

const DefaultSelect = (props) => <select {...props} />
const DefaultOption = (props) => <option {...props} />
const DefaultInput = (props) => <input {...props} />

const DefaultContainer = (props) => {
  return <div {...props} />
}

const DefaultToolbar = (props) => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'baseline',
        paddingBottom: '1rem',
        marginBottom: '1rem',
        borderBottom: '1px solid',
      }}
      {...props}
    />
  )
}

DefaultToolbar.propTypes = {
  children: PropTypes.node.isRequired,
}

const DefaultLabel = (props) => {
  return (
    <label style={{ display: 'inline-block', paddingRight: '1rem' }}>
      <div>
        <strong>{props.text}</strong>
      </div>
      <div>{props.children}</div>
    </label>
  )
}

DefaultLabel.propTypes = {
  text: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,

  // Can be used to show the value alongside the label, ex. font size
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

class ControlledTypeTester extends Component {
  constructor(props) {
    super(props)

    let initialState = {
      scale: true,
      fontSize: 16,
      // lineHeight: props.defaultLineHeight,
    }

    // TODO Support array of config objects, for control groups?
    // TODO Object.assign doesn’t seem to deep merge object, might want
    //      to iterate over control objects (ex. fontFamily) and run
    //      Object.assign on each of those instead. Then, you can keep
    //      the default config, ex. font family is still a dropdown, even
    //      when I provide my own options.

    this.controlConfig = Object.assign({}, supportedProperties, props.controls)
    this.controlKeys = Object.keys(this.controlConfig)

    // Create initial state from control defaults
    this.controlKeys.forEach((key, i) => {
      let obj = this.controlConfig[key]

      initialState[key] = obj.defaultValue
    })

    this.keyPrefix = this.getKeyPrefix(props)

    this.state = initialState
    this.handleOnChange = this.handleOnChange.bind(this)
  }

  handleOnChange(e) {
    // TODO e.target.checked
    const value = e.target.value
    const name = e.target.name

    let newState = {}
    newState[name] = value

    // TODO Same for auto-lineHeight
    if (name === 'fontSize') {
      newState['scale'] = false
    }
    this.setState(newState)
  }

  getStyleFromState(state) {
    let result = {}

    for (let key in state) {
      // Don’t add if not supported, or value is 0
      if (supportedProperties[key] && state[key] !== 0) {
        if (supportedProperties[key] && supportedProperties[key].units) {
          // Add to result with units
          result[key] = `${state[key]}${supportedProperties[key].units}`
        } else {
          // Add to result
          result[key] = state[key]
        }
      }
    }

    // Remove fontSize if we are auto-scaling
    // TODO DO this for auto-lineHeight as well
    if (state.scale) {
      delete result['fontSize']
    }

    return result
  }

  getKeyPrefix(props) {
    props = props || this.props
    if (!props || !props.value) {
      return ''
    }

    return props.value.substring(0, 10)
  }

  createControl(controlObj) {
    let obj = Object.assign({}, controlObj)
    const { Input, Slider, Select, Option } = this.props.components
    const keyPrefix = `${obj.type}_${this.keyPrefix}`

    if (obj.component) {
      let CustomInput = obj.component
      return <CustomInput {...obj} />
    }

    switch (obj.type) {
      case 'select':
        let options = obj.options
        delete obj.defaultValue

        return (
          <Select {...obj} key={keyPrefix}>
            {options
              ? options.map((opt, index) => {
                  if (typeof opt === 'string') {
                    opt = { value: opt, label: opt }
                  }
                  // TODO This key isn’t good enough, easy to
                  //      be the same if there are many testers
                  //      on a single page.
                  let optKey = `${keyPrefix}_${opt.value}_${index}`
                  log('optKey', optKey)
                  return (
                    <Option key={optKey} value={opt.value}>
                      {opt.label}
                    </Option>
                  )
                })
              : null}
          </Select>
        )
        break
      case 'checkbox':
        return <Input {...obj} checked={obj.defaultValue} key={keyPrefix} />
        break
      case 'range':
        return <Slider {...obj} key={keyPrefix} />
        break
      default:
        delete obj.defaultValue
        return <Input {...obj} key={keyPrefix} />
    }
  }

  getControlsFromState(state) {
    const controlKeys = this.controlKeys
    const controlConfig = this.controlConfig
    const Label = this.props.components.Label
    let controls = []

    // How far does this go, until I’m re-creating JSON form schema?
    controlKeys.forEach((key, index) => {
      let obj = controlConfig[key]
      let keyStr = `ControlledTypeTester_${key}_${index}`
      let value = state[key]
      obj.key = keyStr
      obj.onChange = this.handleOnChange
      obj.name = key
      obj.value = value

      controls.push(
        <Label
          key={`Label_${this.keyPrefix}_${index}`}
          text={obj.label}
          value={obj.value}>
          {this.createControl(obj)}
        </Label>
      )
    })

    return controls
  }

  render() {
    const props = this.props
    const state = this.state
    const Toolbar = props.components.Toolbar
    const Container = props.components.Container
    const remainingProps = Object.assign({}, props)
    delete remainingProps.controls
    let controls = this.getControlsFromState(state)
    let style = this.getStyleFromState(state)

    return (
      <div>
        {/* <pre>
          <code>{JSON.stringify(style)}</code>
        </pre>
        <pre>
          <code>{JSON.stringify(state)}</code>
        </pre> */}
        <Toolbar>{controls}</Toolbar>
        <Container style={style}>
          <TypeTester
            {...remainingProps}
            scale={state.scale}
            scaleOnEdit={!state.scale}
            scaleOnLineBreak={false}
            externallyControlled={!state.scale}
            onChangeFontSize={(fontSize, detail) => {
              console.log('on change font size', fontSize, detail)
              this.setState({
                fontSize: parseInt(fontSize, 10),
                // scale: false,
              })
              if (
                typeof props.onChangeFontSize !== 'undefined' &&
                typeof props.onChangeFontSize === 'function'
              ) {
                props.onChangeFontSize()
              }
            }}
          />
        </Container>
      </div>
    )
  }
}

ControlledTypeTester.defaultProps = {
  components: {
    Label: DefaultLabel,
    Toolbar: DefaultToolbar,
    Container: DefaultContainer,
    Select: DefaultSelect,
    Option: DefaultOption,
    Input: DefaultInput,
    Slider: DefaultSlider,
  },
  controls: supportedProperties,
}

export default ControlledTypeTester
