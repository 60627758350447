// Minimal required Draft.js and Typor styles
import 'draft-js/dist/Draft.css'
import '../../src/TypeTester.css'

import React, { Component } from 'react'
import { render } from 'react-dom'
import { Router, Link } from '@reach/router'

// Live development version for working on the library
// Normally you would do:
// import { TypeTester } from 'typor'
import { TypeTester, ControlledTypeTester } from '../../src'

// Can’t import outside this dir with nwb
import CycleTypeTesterValue from './CycleTypeTesterValue.js'
import CycleTypeTesterScale from './CycleTypeTesterScale.js'
import ScaledFontSizeControl from './ScaledFontSizeControl.js'
import MultiLineViewportUnits from './MultiLineViewportUnits.js'

// Pages
import TestCases from './test-cases'

class MultipleTesters extends Component {
  render() {
    return (
      <ul>
        {this.props.items.map((val, i) => {
          let itemKey = `${val}_${i}`
          return (
            <li key={itemKey}>
              <TypeTester value={val} scale={false} editorKey={itemKey} />
            </li>
          )
        })}
      </ul>
    )
  }
}

MultipleTesters.defaultProps = {
  items: ['One', 'Two', 'Three'],
}

class Home extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <div>
        <nav style={{ marginBottom: '2rem' }}>
          <div>
            <a href="/storybook" style={{ color: 'white' }}>
              Storybook
            </a>
          </div>
        </nav>
        <link
          href="https://fonts.googleapis.com/css?family=Work+Sans:500"
          rel="stylesheet"
        />
        <ScaledFontSizeControl />
        <ScaledFontSizeControl
          value={`The quick brown fox\nand make this all the same\nwhite-space-default class is also a factor`}
          scaleOnLineBreak={false}
        />
        <ScaledFontSizeControl
          value={`Now available in rose quartz,\npowder blue, hunter green,\nand sunset orange.`}
          scaleOnLineBreak={false}
        />
        <ScaledFontSizeControl
          value={`The quick brown fox\nand make this all the same\nwhite-space-default class is also a factor`}
          scaleOnLineBreak={false}
          testViewportUnits
        />
        <MultiLineViewportUnits />
        <ControlledTypeTester value="The quick brown fox…" />
        <style>
          {`
          body {
            background-color: black;
            color: white;
            font-family: "Work Sans", "Avenir Next", "Avenir", sans-serif;
            padding: 1rem;
          }`}
        </style>
        <TypeTester
          onChangeFontSize={newFontSize => {
            console.log('onChangeFontSize', newFontSize)
          }}
          scale
          value="Scale while typing and on resize"
        />
        <hr />
        <TypeTester
          value="Scale once, and on resize"
          scale
          scaleOnEdit={false}
          scaleOnResize={true}
        />
        <hr />
        <TypeTester
          value="Scale once, then don’t"
          scale={true}
          scaleOnEdit={false}
          scaleOnResize={false}
        />
        <hr />
        <hr />
        <TypeTester readOnly value="Hello read only" />
        <hr />
        <style>{`.padding { padding: 3rem; border: 2px dashed; }`}</style>
        <TypeTester
          className="padding"
          value="Click for focus asdf"
          scale={false}
          onClick={e => {
            console.log('analytics example', {
              category: 'Type Tester',
              action: 'Click',
              label: e.target.value,
            })
          }}
        />
        <hr />
        <TypeTester
          value={`This should be a multiline\nexample with a new caret`}
          caret
          scale={false}
        />
        <hr />
        <p>
          Multiple editors with custom <code>editorKeys</code>
        </p>
        <MultipleTesters />
        <CycleTypeTesterValue />
        {/*
        <CycleTypeTesterScale />
        */}
      </div>
    )
  }
}

const Site = props => {
  return (
    <Router>
      <Home path="/" />
      <TestCases path="/test-cases" />
    </Router>
  )
}

render(<Site />, document.querySelector('#demo'))
