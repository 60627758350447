const log = function() {
  if (
    typeof process !== 'undefined' &&
    typeof process.env !== 'undefined' &&
    typeof process.env.NODE_ENV !== 'undefined' &&
    process.env.NODE_ENV === 'development'
  ) {
    console.log(...arguments)
  }
}

export default log
