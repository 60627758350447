import React from 'react'
import { TypeTester } from '../../src'

// This one doens’t quite work, but it’s close.
// Would probably need to keep working on
// forceEditorUpdateViaFocus workaround

class CycleTypeTesterScale extends React.Component {
  constructor() {
    super()
    this.state = { valueIndex: 0 }
    this.handleCycle = this.handleCycle.bind(this)
  }

  handleCycle() {
    let newIndex = this.state.valueIndex + 1
    let increase = this.props.valueList.length - 1 >= newIndex

    this.setState({ valueIndex: increase ? newIndex : 0 })

    this.timeout = setTimeout(this.handleCycle, this.props.timeout)
  }

  componentWillUnmount() {
    clearTimeout(this.timeout)
  }

  componentDidMount() {
    this.timeout = setTimeout(() => {
      this.handleCycle()
    }, this.props.timeout)
  }

  render() {
    let value = this.props.valueList[this.state.valueIndex]
    let fontSize = `${value}px`
    let scale = false
    if (value === 'scale') {
      // TODO Would be nice if this still worked
      // even if there was a font size set
      fontSize = null
      scale = true
    }

    return (
      <div style={{ fontSize: fontSize }}>
        {fontSize} {scale.toString()}
        <TypeTester
          scale={scale}
          externallyControlled={true}
          value="The quick brown fox…"
        />
      </div>
    )
  }
}

CycleTypeTesterScale.defaultProps = {
  timeout: 1000,
  valueList: ['scale', 50],
}

export default CycleTypeTesterScale
