import React from 'react'
import { ControlledTypeTester, TypeTester } from '../../src'

// This is a hack until those other components are actually accessible
const Components = ControlledTypeTester.defaultProps.components

const Label = props => {
  return (
    <label
      style={{
        padding: '0 1rem',
        width: 'auto',
        minWidth: '20%',
      }}>
      <strong>{props.text}</strong>
      <br />
      {props.children}
    </label>
  )
}

class ScaledFontSizeControl extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      fontSize: 16,
      lineHeight: props.defaultLineHeight,
      auto: true,
    }
  }

  render() {
    const props = this.props
    const state = this.state
    let fontSizeWithUnits = `${state.fontSize}px`
    const {
      externallyControlled,
      scale,
      scaleOnEdit,
      ...remainingProps
    } = props

    return (
      <div>
        <Components.Toolbar>
          <Components.Label text={`Font Size (${fontSizeWithUnits})`}>
            <input
              type="range"
              min={props.minFontSize}
              max={props.maxFontSize}
              value={state.fontSize}
              onChange={e => {
                if (e && e.target) {
                  this.setState({
                    auto: false,
                    fontSize: e.target.valueAsNumber || e.target.value,
                  })
                }
              }}
            />
          </Components.Label>
          <Components.Label text={`Line Height (${this.state.lineHeight})`}>
            <input
              type="number"
              min={1}
              max={2}
              step={0.1}
              value={state.lineHeight}
              onChange={e => {
                if (e && e.target) {
                  this.setState({
                    auto: false,
                    lineHeight: e.target.valueAsNumber || e.target.value,
                  })
                }
              }}
            />
          </Components.Label>
          <Components.Label text="Font">
            <select>
              <option>Example Sans</option>
              <option>This is a quick example</option>
              <option>Many more controls are supported</option>
            </select>
          </Components.Label>
        </Components.Toolbar>
        <Components.Container
          style={{
            fontSize: fontSizeWithUnits,
            fontSize: state.auto ? undefined : fontSizeWithUnits,
            lineHeight: state.lineHeight,
          }}>
          <TypeTester
            externallyControlled={!state.auto}
            scale={state.auto ? true : false}
            scaleOnEdit={state.auto ? true : false}
            onChangeFontSize={(fontSize, detail) => {
              console.log('on change font size', fontSize, detail)
              this.setState({ fontSize })
              if (typeof props.onChangeFontSize === 'function') {
                props.onChangeFontSize()
              }
            }}
            {...remainingProps}
          />
        </Components.Container>
      </div>
    )
  }
}

ScaledFontSizeControl.defaultProps = {
  minFontSize: 20,
  maxFontSize: 200,
  defaultLineHeight: 1.5,
}

export default ScaledFontSizeControl
