import React from 'react'
import { convertToRaw } from 'draft-js'
import { TypeTester } from '../../src'

class CycleTypeTesterValue extends React.Component {
  constructor() {
    super()
    this.state = {
      phraseListIndex: 0,
    }
    this.handleCycle = this.handleCycle.bind(this)
  }

  handleCycle() {
    let newIndex = this.state.phraseListIndex + 1
    let increase = this.props.phraseList.length - 1 >= newIndex

    this.setState({ phraseListIndex: increase ? newIndex : 0 })

    this.timeout = window.setTimeout(this.handleCycle, this.props.timeout)
  }

  componentDidMount() {
    this.timeout = window.setTimeout(() => {
      this.handleCycle()
    }, this.props.timeout)
  }

  componentWillUnmount() {
    clearTimeout(this.timeout)
  }

  render() {
    return (
      <div
        style={{
          height: '300px',
          display: 'flex',
          alignItems: 'center',
          width: '100%',
        }}>
        <div style={{ width: '100%' }}>
          <TypeTester
            scale
            value={this.props.phraseList[this.state.phraseListIndex]}
            onChange={currentContent => {
              // currentContent is ContentState from Draft.js
              // It’s unlikely you want anything from it, but it is
              // provided in case.
              let raw = convertToRaw(currentContent)

              if (
                raw &&
                raw.blocks.length >= 1 &&
                raw.blocks[0] &&
                raw.blocks[0].text
              ) {
                let currentContentNotInPhraseList =
                  this.props.phraseList.indexOf(raw.blocks[0].text) === -1

                if (currentContentNotInPhraseList) {
                  // Stop cycling, if content is edited
                  clearTimeout(this.timeout)
                }
              }
            }}
          />
        </div>
      </div>
    )
  }
}

CycleTypeTesterValue.defaultProps = {
  timeout: 3000,
  phraseList: [
    'FILLES DU CALVAIRE',
    'SAINT-SÉBASTIEN – FROISSAR',
    'CHEMIN VERT',
  ],
}

export default CycleTypeTesterValue
