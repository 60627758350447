import React, { PureComponent } from 'react'
import { EditorBlock } from 'draft-js'
import { getComputedStyle, getComputedDimensions } from './utils/get-computed'
import PropTypes from 'prop-types'
import _debounce from 'lodash.debounce'

class TypeLine extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      previousParentWidth: undefined,
      fontSize: 'inherit',
      whiteSpace: 'nowrap',
      wordBreak: undefined,
      // outline: '2px solid purple',
      scaled: false,
      ratio: null,
    }

    this.el = null
    this.resizeFonts = this.resizeFonts.bind(this)

    this.handleResize = this.handleResize.bind(this)
    this.handleResize = _debounce(this.handleResize, 100)
  }

  handleResize() {
    const blockProps = this.props.blockProps
    const previouslyScaled = this.state.scaled

    if (blockProps.scaleOnResize) {
      if (previouslyScaled === false) {
        this.resizeFonts()
      } else {
        this.resizeFonts({
          using: 'ratio',
        })
      }
    }

    if (this.getScaledForState()) {
      this.setState({
        scaled: true,
      })
    }
  }

  setCurrentHeight() {
    if (this.el && this.el.parentNode) {
      this.setState({
        previousParentWidth: this.el.parentNode.offsetWidth,
      })
    }
  }

  componentDidMount() {
    // Not using this here, even though we could. Instead, in
    // componentDidUpdate, we only run resize fonts when fontsLoaded
    // is true. Might also
    if (this.props.blockProps.fontsLoaded === true) {
      this.resizeFonts()
    }

    this.setCurrentHeight()
    window.addEventListener('resize', this.handleResize, false)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize, false)
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const props = this.props
    const prevBlockProps = prevProps.blockProps
    const fontsNowLoaded =
      prevBlockProps.fontsLoaded === false &&
      props.blockProps.fontsLoaded === true

    if (fontsNowLoaded) {
      return this.resizeFonts()
    }

    if (props.blockProps.fontsLoaded && props.blockProps.scaleOnEdit) {
      if (
        prevState.fontSize === 'inhert' ||
        prevProps.block.text !== props.block.text
      ) {
        return this.resizeFonts()
      }
    }
  }

  // TODO
  getFontSizeForMultipleLines(newFontSize) {
    return newFontSize
  }

  getViewportWidthRatio(el, opts) {
    opts = opts || {}

    if (!el || !el.parentNode || !opts.fontSize) {
      return null
    }

    const parentWidth = parseFloat(el.parentNode.offsetWidth, 10)

    return opts.fontSize / parentWidth
  }

  getFontSizeInViewportWidths(el, opts) {
    opts = opts || {}

    if (
      !el ||
      !el.parentNode ||
      (!opts.fontSize && !opts.ratio) ||
      typeof document === 'undefined' ||
      typeof document.documentElement === 'undefined' ||
      typeof document.documentElement.clientWidth === 'undefined'
    ) {
      return null
    }

    const parentWidth = parseFloat(el.parentNode.offsetWidth, 10)
    const ratio = opts.ratio || this.getViewportWidthRatio(el, opts)

    if (!ratio) {
      return null
    }

    // console.log('ratio', ratio)
    let parentViewportRatio = parentWidth / document.documentElement.clientWidth
    // console.log('parentViewportRatio', parentViewportRatio)
    let vwFontSize = ratio * parentViewportRatio * 100
    // console.log(`${vwFontSize}vw`)

    return vwFontSize
  }

  getFontSize(el, opts) {
    opts = opts || {}
    if (!el || !el.parentNode) {
      return null
    }

    const blockProps = this.props.blockProps
    const maxWidth = getComputedDimensions(el.parentNode).width
    const currentStyle = getComputedStyle(el)
    const startingFontSize = parseFloat(
      currentStyle.getPropertyValue('font-size'),
      10
    )
    const previousParentWidth = this.state.previousParentWidth
    const newParentWidth = parseFloat(el.parentNode.offsetWidth, 10)

    let extra = 1
    let newFontSize = startingFontSize

    // Calculate based on ratio, for subsequent resizes
    if (opts && typeof opts.using !== 'undefined') {
      newFontSize = (startingFontSize * newParentWidth) / previousParentWidth

      if (opts.using === 'ratio') {
        this.setCurrentHeight()
      }
    } else {
      // Single line
      newFontSize = (startingFontSize / el.scrollWidth) * maxWidth
      newFontSize = Math.floor(newFontSize - extra)
    }

    if (newFontSize > blockProps.maxFontSize) {
      newFontSize = blockProps.maxFontSize
    }

    if (newFontSize < blockProps.minFontSize) {
      newFontSize = blockProps.minFontSize
    }

    return newFontSize
  }

  resizeFonts(opts) {
    const props = this.props
    const blockProps = props.blockProps
    const minFontSize = blockProps.minFontSize
    const maxFontSize = blockProps.maxFontSize
    const breakWord =
      blockProps.scaleOnEdit === false || blockProps.testViewportUnits

    let fontSize = this.getFontSize(this.el, opts) || this.state.fontSize
    let styleState = {
      fontSize: fontSize,
    }

    if (props.blockProps.testViewportUnits && !this.state.ratio) {
      let ratio = this.getViewportWidthRatio(this.el, { fontSize: fontSize })
      styleState['ratio'] = ratio
    }

    // TODO Can `break-word` this be independently configurable, or is it necessary?
    if (fontSize <= minFontSize) {
      styleState.fontSize = minFontSize
      styleState.whiteSpace = 'normal'
      styleState.wordBreak = 'break-word'
      // styleState.overflowWrap = 'break-word'
      // styleState.outline = '2px solid orange'
    } else if (fontSize >= maxFontSize) {
      styleState.fontSize = maxFontSize
      styleState.whiteSpace = 'nowrap'
      // styleState.outline = '2px solid yellow'
    } else {
      if (breakWord) {
        styleState.whiteSpace = 'normal'
        styleState.wordBreak = 'break-all' // For Firefox, #16
        // styleState.overflowWrap = 'break-word'
        // styleState.outline = '2px solid green'
      } else {
        styleState.whiteSpace = 'nowrap'
        // styleState.outline = '2px solid blue'
      }
    }

    if (this.getScaledForState()) {
      styleState['scaled'] = true
    }

    this.setState(styleState, () => {
      if (
        blockProps.onChangeFontSize &&
        typeof blockProps.onChangeFontSize === 'function'
      ) {
        let detail = {
          px: styleState.fontSize,
        }

        if (props.blockProps.testViewportUnits && styleState.ratio && this.el) {
          let fontSizeVW = this.getFontSizeInViewportWidths(this.el, {
            ratio: styleState.ratio,
            fontSize: styleState.fontSize,
          })

          if (fontSizeVW) {
            detail['vw'] = fontSizeVW
          }
        }

        blockProps.onChangeFontSize(this.state.fontSize, detail)
      }
    })
  }

  getScaledForState() {
    return !this.state.scaled && !this.props.blockProps.scaleOnEdit
  }

  render() {
    const props = this.props
    const state = this.state
    const scaled = state.scaled
    const useEditorForMeasurement = !props.blockProps.valueMeasure

    let style = Object.assign({}, state)
    delete style.scaled
    delete style.previousParentWidth
    delete style.ratio
    style['display'] = 'inline-block'

    if (props.blockProps.testViewportUnits && state.ratio && this.el) {
      let fontSizeVW = this.getFontSizeInViewportWidths(this.el, {
        ratio: state.ratio,
        fontSize: style.fontSize,
      })

      if (fontSizeVW) {
        style.fontSize = `${fontSizeVW}vw`
      }
    }

    style.fontSize =
      typeof style.fontSize === 'number'
        ? `${style.fontSize}px`
        : style.fontSize

    let classNameNamespace = `${props.blockProps.classNameNamespace}Line`
    let className = [classNameNamespace]

    if (props.blockProps.scaleOnLineBreak !== false) {
      className.push('white-space-default')
    }

    if (props.blockProps.fontsLoaded) {
      className.push(`${classNameNamespace}-loaded`)
    }

    className = className.join(' ')
    const editorBlock = <EditorBlock {...props} />

    // Typically, we don’t provide a dedicated valueMeasure prop, because we are going
    // to measure the entire value within the editor component and make it into one
    // line. Right now, the only way this gets set is if scaleOnLineBreak is false and
    // scale is true. Then, we create a hidden element to measure, and that is used
    // to set the font size. In all other situations, we don’t need that.
    if (useEditorForMeasurement === false) {
      return (
        <React.Fragment>
          <span
            ref={el => (this.el = el)}
            style={style}
            className={`${className} ${classNameNamespace}-measure`}>
            <span aria-hidden>{props.blockProps.valueMeasure}</span>
          </span>
          <span style={style} className={className}>
            {editorBlock}
          </span>
        </React.Fragment>
      )
    }

    return (
      <span ref={el => (this.el = el)} style={style} className={className}>
        {editorBlock}
      </span>
    )
  }
}

TypeLine.defaultProps = {
  blockProps: {
    fontsLoaded: null,
    scaleOnResize: true,
    scaleOnEdit: true,
    scaleOnLineBreak: true,

    // Defaults specified in parent and passed
    // classNameNamespace: 'public-TypeTester',
    // minFontSize: PropTypes.number,
    // maxFontSize: PropTypes.number,
  },
}

TypeLine.propTypes = {
  blockProps: PropTypes.shape({
    // Only using this to set state.scaled, which I don’t think is used
    scale: PropTypes.bool,

    // If this is provided, we’ll determine the default
    // scale size using this string, rather than the entire
    // editor string. Not directly exposed to end users, it’s
    // set in TypeTester.js via scale={true} + scaleOnLineBreak={false}
    valueMeasure: PropTypes.string,

    // fontsLoaded: PropTypes.bool.isRequired,
    classNameNamespace: PropTypes.string.isRequired,
    scaleOnResize: PropTypes.bool,
    scaleOnEdit: PropTypes.bool,
    scaleOnLineBreak: PropTypes.bool,
    minFontSize: PropTypes.number.isRequired,
    maxFontSize: PropTypes.number.isRequired,
  }),
}

export default TypeLine
