import React from 'react'
import PropTypes from 'prop-types'

class DefaultSlider extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      value: props.defaultValue,
    }

    this.handleOnChange = this.handleOnChange.bind(this)
  }

  handleOnChange(e) {
    let value = e.target.value
    if (typeof e.target.valueAsNumber !== 'undefined') {
      value = e.target.valueAsNumber
    } else {
      value = parseFloat(e.target.value, 10)
    }

    this.setState({
      value: value,
    })

    if (
      typeof this.props.onChange !== 'undefined' &&
      typeof this.props.onChange === 'function'
    ) {
      return this.props.onChange(e)
    }
  }

  render() {
    const props = this.props
    const { defaultValue, onChange, value, units, ...remainingProps } = props

    return (
      <div style={{ display: 'flex' }}>
        <div>
          <input
            {...remainingProps}
            type="range"
            value={value}
            onChange={this.handleOnChange}
          />
        </div>
        <div style={{ display: 'flex' }}>
          <div style={{ position: 'relative' }}>
            <input
              style={{
                padding: 0,
                appearance: 'none',
                border: 'none',
                fontSize: 'inherit',
                fontFamily: 'inherit',
                position: 'relative',
                background: 'transparent',
              }}
              {...remainingProps}
              type="number"
              value={value}
              onChange={this.handleOnChange}
            />
            <div
              style={{
                pointerEvents: 'none',
                padding: 0,
                appearance: 'none',
                opacity: 0.5,
                fontSize: 'inherit',
                position: 'absolute',
                top: 0,
                left: 0,
              }}>
              <span style={{ opacity: 0 }} aria-hidden>
                {value}
              </span>
              {units}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

DefaultSlider.defaultProps = {
  defaultValue: 0,
  min: 0,
  max: 100,
  step: 1,
}

DefaultSlider.propTypes = {
  units: PropTypes.string,
  defaultValue: PropTypes.number,
  min: PropTypes.number,
  max: PropTypes.number,
  step: PropTypes.number,
  onChange: PropTypes.func,
}

export default DefaultSlider
