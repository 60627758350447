import 'focus-options-polyfill'
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import _debounce from 'lodash.debounce'
import FontFaceObserver from 'fontfaceobserver'
import {
  ContentState,
  Editor,
  EditorState,
  convertFromRaw,
  RichUtils,
} from 'draft-js'

// Ours
import { getComputedStyle } from './utils/get-computed'
import log from './utils/log'
import invalidProp from './utils/invalid-prop'
import TypeLine from './TypeLine'

class TypeTester extends PureComponent {
  constructor(props) {
    super(props)

    let lines = props.value.split(/[\n\r]/g)

    if (props.scaleOnLineBreak) {
      lines = lines.map((line, index) => {
        return {
          text: line,
          key: `TypeTester_${line.substring(0, 10)}_${index}`,
          type: 'unstyled',
          entityRanges: [],
        }
      })
    } else {
      lines = [
        {
          text: lines.join('\n'),
          key: `TypeTester_${lines[0]}_0`,
          type: 'unstyled',
          entityRanges: [],
        },
      ]
    }

    const emptyContentState = convertFromRaw({
      entityMap: {},
      blocks: lines,
    })

    this.state = {
      editorState: EditorState.createWithContent(emptyContentState),
      valueOriginal: this.props.value,
      valueLabel: this.props.value.substr(0, 30),
      fontsLoaded: false,
      caret: this.props.caret !== false,
    }

    this.handleEditorParentRef = (ref) => (this.editorParentRef = ref)
    this.handleEditorRef = (ref) => (this.editorRef = ref)

    this.handleOnClickParent = this.handleOnClickParent.bind(this)
    this.handleOnFocus = this.handleOnFocus.bind(this)
    this.handleOnChange = this.handleOnChange.bind(this)
    this.handleOnChangeCallback = this.handleOnChangeCallback.bind(this)
    this.handleAnyChange = this.handleAnyChange.bind(this)
    this.handleLineBreakInline = this.handleLineBreakInline.bind(this)
    this.initFontCheck = this.initFontCheck.bind(this)
    this.myBlockRenderer = this.myBlockRenderer.bind(this)
  }

  handleOnClickParent(e) {
    if (this.editorRef) {
      this.setState({ caret: false })
      this.editorRef.focus()
    }
  }

  handleOnFocus(e) {
    const props = this.props

    // `forceEditorUpdateViaFocus` forces focus on `this.editorRef`
    // which triggers `handleOnFocus`, removing the cursor if we have
    // the cursor currently enabled, ex. on initial load.
    // This conditional works around this, and only clears the cursor onFocus,
    // if the focused DOM element is still the editor. `setTimeout` might not
    // really be necessary in this case.
    window.setTimeout(() => {
      if (
        document.activeElement &&
        this.editorRef &&
        document.activeElement === this.editorRef.editor
      ) {
        this.setState({ caret: false })
      }

      return
    }, 1)

    if (props.onClick) {
      if (e && e.target && !e.target.value) {
        e.target.value = this.state.valueLabel
      }
      return props.onClick(e)
    }
  }

  handleOnChange(editorState) {
    const selectionState = editorState.getSelection()

    // const currentBlock = currentContent.getBlockForKey(
    //   selectionState.getStartKey()
    // )

    // forceSelection or acceptSelection is required to trigger
    // a re-render each time, otherwise the blockProps aren’t
    // passed along
    // until you remove focus
    // https://github.com/facebook/draft-js/issues/148#issuecomment-191971251
    // https://github.com/facebook/draft-js/issues/167#issuecomment-193245837
    // https://gist.github.com/tomcask/6040f6695712df5d31645e48bda3fc2b#gistcomment-3081832
    this.setState(
      {
        editorState: EditorState.acceptSelection(editorState, selectionState),
      },
      () => this.handleOnChangeCallback(editorState)
    )
  }

  handleOnChangeCallback(editorState) {
    const props = this.props
    editorState = editorState || this.state.editorState
    let currentContent = editorState.getCurrentContent()

    // onChange callback lets you handle other interactions
    // outside of the type tester, based on the content
    // of the type tester.
    if (props.onChange && typeof props.onChange === 'function') {
      props.onChange(currentContent)
    }

    return this.handleAnyChange()
  }

  handleAnyChange(e) {
    const scaleOnce = !this.props.scaleOnEdit

    // Line breaking is prevented when using scaleOnEdit={false}
    // (ie. the behaviour that used to be scale="once")
    // Generally, we want this because you want the line to start
    // wrapping after that size.
    // TODO Could remove this and allow line breaks when scaling,
    // by pulling the font size out of the first block and applying
    // it to subsequent blocks, but it isn’t high priority.
    if (typeof e !== 'undefined' && scaleOnce) {
      // Prevent default when we’re only scaling once
      return true
    }

    // Otherwise, don’t prevent default
    return false
  }

  // https://github.com/HubSpot/draft-convert/issues/83#issuecomment-319442328
  handleLineBreakInline(e) {
    const blockType = RichUtils.getCurrentBlockType(this.state.editorState)

    // If you only wanted Shift + Return soft line breaks, you could
    // also check `!KeyBindingUtil.isSoftNewlineEvent(e)
    if (blockType !== 'unstyled') {
      return 'not_handled'
    }

    // Rather than outright preventing line breaks,
    // replace them with a soft new line
    const newState = RichUtils.insertSoftNewline(this.state.editorState)
    this.handleOnChange(newState)

    return 'handled'
  }

  resetState(val) {
    if (typeof val === 'undefined') {
      return {}
    }

    return {
      editorState: EditorState.createWithContent(
        ContentState.createFromText(val)
      ),
      valueOriginal: val,
      valueLabel: val.substr(0, 30),
      fontsLoaded: this.state.fontsLoaded,
      scaled: false,
    }
  }

  componentWillUnmount() {
    window.clearTimeout(this.timeoutReRun)
    window.clearTimeout(this.timeoutResizeAll)
  }

  componentDidMount() {
    this.initFontCheck()

    /*
    Hack for Safari v10.0 and lower to re-run the numbers because it
    seems to get it wrong the first time, but then it works
    once you click or resize. There is probably another way
    around this, but it will be replacable by fixing the
    approach with TypeLine and the fontSize state too
      This issue also interfering with scale="once" support in Safari
      Possible is only using 50% of the width?
      Actually this might have something to do with Promises or other
      ES6 things, because most of it was added in Safari v10.1
    */
    this.timeoutReRun = window.setTimeout(() => {
      window.clearTimeout(this.timeoutReRun)
      if (this.state.fontsLoaded === true) {
        this.initFontCheck()
      }
    }, 500)
  }

  forceEditorUpdateViaFocus() {
    if (document) {
      // Get the current focus
      let currentFocus = document.activeElement

      // Set the current focus to the editor
      window.setTimeout(() => {
        return this.editorRef.focus({ preventScroll: true })
      }, 1)

      window.setTimeout(() => {
        if (
          !currentFocus ||
          currentFocus === document.body ||
          // With a component switching between externallyControlled and not,
          // it’s possible to get the TypeTester as the focused element itself.
          // In that case, we also want to clear it.
          currentFocus.className.includes('Draft')
        ) {
          // Clear the focus
          document.activeElement.blur()
        } else {
          // Restore the focus to what it was before
          currentFocus.focus()
        }
      }, 2)
    }
  }

  // This also worked where forceEditorUpdateViaFocus()
  // worked, so could be a different aproach to use. Would
  // instead use EditorState.forceSelection to force focus,
  // and then would clear it in the same way as
  // is currently done with forceEditorUpdateViaFocus()
  //   Force block renderer to re-render when the
  // fonts are loaded. Block renderers / block props
  // don’t re-render with editor state change by default.
  // This works around that:
  // https://github.com/facebook/draft-js/issues/458#issuecomment-272531222
  // forceBlockReRender() {
  //   const editorState = this.state.editorState
  //   const currentSelection = editorState.getSelection()
  //   let forcedState = EditorState.forceSelection(editorState, currentSelection)
  //   this.setState({
  //     editorState: forcedState,
  //   })
  // }

  componentDidUpdate(prevProps, prevState) {
    const props = this.props

    if (
      props.externallyControlled &&
      props.externallyControlled !== prevProps.externallyControlled
    ) {
      this.forceEditorUpdateViaFocus()
    }

    // Check to see if value has changed from originally set value,
    // ex. for cycling through different defaults. Could choose
    // stop the cycle once the state has been edited once.
    //   Decided to update this to use `componentDidUpdate`,
    // rather than `getDerivedStateFromProps`
    if (
      typeof props.value !== 'undefined' &&
      props.value !== prevProps.value &&
      props.value !== prevState.valueOriginal
    ) {
      let newState = this.resetState(props.value)
      this.setState(newState, this.handleOnChangeCallback)
    }
  }

  // https://developer.mozilla.org/en-US/docs/Web/CSS/font-weight#Values
  getFontWeightNumber(value) {
    if (value === 'normal') {
      return 400
    } else if (value === 'bold') {
      return 700
    }

    return value
  }

  initFontCheck() {
    const el = this.editorParentRef
    const props = this.props
    const state = this.state

    if (state.fontsLoaded) {
      log('Fonts already loaded')
      return
    }

    const computedStyle = getComputedStyle(el)
    let fontFamilies = computedStyle.getPropertyValue('font-family')
    fontFamilies = fontFamilies.split(',')
    fontFamilies = fontFamilies.map((family) => {
      family = family.replace(/['"]+/g, '')
      family = family.trim()
      return family
    })

    // const fontStretch = computedStyle.getPropertyValue('font-stretch')
    let fontWeight = computedStyle.getPropertyValue('font-weight').toString()
    fontWeight = this.getFontWeightNumber(fontWeight)

    let fallbackIndex = 0
    const createObserver = () => {
      let fontFamily = fontFamilies[fallbackIndex]
      if (typeof fontFamily === 'undefined') {
        console.error(
          `All fonts in \`font-family\` stack are unavailable: ${fontFamilies.join(
            ', '
          )}`
        )
        return false
      }

      const observer = new FontFaceObserver(fontFamily, { weight: fontWeight })

      observer
        .load(props.fontFaceObserver.testString, props.fontFaceObserver.timeout)
        .then(() => {
          this.setState({ fontsLoaded: true }, () => {
            this.forceEditorUpdateViaFocus()
          })
        })
        .catch((err) => {
          fallbackIndex = fallbackIndex + 1

          console.warn(
            `Trying fallback font ${fallbackIndex}. Couldn’t find \`font-family\` ${fontFamily}`
          )

          createObserver()
        })
    }

    createObserver()
  }

  myBlockRenderer(contentBlock) {
    const state = this.state
    const props = this.props
    const type = contentBlock.getType()
    const scale = props.scale

    if (type === 'unstyled' && scale) {
      let valueMeasure = null
      if (props.scale && props.scaleOnLineBreak === false) {
        let valueSplit = props.value.toString().split(/[\n\r]/g)
        if (valueSplit.length >= 2) {
          valueMeasure = valueSplit[0]
        }
      }

      return {
        component: TypeLine,
        editable: !props.readOnly,
        // Passed to TypeLine as props.blockProps
        props: {
          valueMeasure: valueMeasure,
          classNameNamespace: props.classNameNamespace,
          scale: scale,
          scaleOnResize: props.scaleOnResize,
          scaleOnEdit: props.scaleOnEdit,
          scaleOnLineBreak: props.scaleOnLineBreak,
          testViewportUnits: props.testViewportUnits,
          fontsLoaded: state.fontsLoaded,
          minFontSize: props.minFontSize,
          maxFontSize: props.maxFontSize,
          initFontCheck: this.initFontCheck,
          onChangeFontSize: props.onChangeFontSize,
          // ,
          // onClick: () => {
          // console.log('click')
          // }
        },
      }
    }
  }

  render() {
    const props = this.props
    const state = this.state
    const namespace = props.classNameNamespace

    let classes = [
      namespace,
      props.className,
      state.caret ? `${namespace}-caret` : '',
    ].join(' ')

    let remainingProps = {
      readOnly: props.readOnly,
      placeholder: props.placeholder,
      textAlignment: props.textAlignment,
      editorKey: props.editorKey,
    }

    return (
      <div
        style={props.style}
        className={classes}
        onClick={this.handleOnClickParent}
        ref={this.handleEditorParentRef}>
        <Editor
          ref={this.handleEditorRef}
          editorState={state.editorState}
          onChange={this.handleOnChange}
          onFocus={this.handleOnFocus}
          handleReturn={
            props.scaleOnLineBreak
              ? this.handleAnyChange
              : this.handleLineBreakInline
          }
          stripPastedStyles={true}
          spellCheck={false}
          handleKeyCommand={(command, editorState) => {
            if (props.scaleOnLineBreak === false) {
              if (command == 'split-block') {
                // TODO This prevents line breaks entirely—could also create a new
                // editor state, that allows a soft line break in the same
                // block, and give that to setState
                // Modifier.mergeBlockData?

                return 'handled'
              }
              return 'not-handled'
            }
          }}
          blockRendererFn={this.myBlockRenderer}
          {...remainingProps}
        />
      </div>
    )
  }
}

TypeTester.defaultProps = {
  // Draft
  className: '',
  classNameNamespace: 'public-TypeTester',
  placeholder: 'Edit…',
  readOnly: false,
  textAlignment: 'left',
  value: '',

  // TypeTester
  externallyControlled: false,
  scale: false,
  scaleOnEdit: true,
  scaleOnLineBreak: true,
  scaleOnResize: true,
  minFontSize: 16,
  maxFontSize: 500,
  caret: false,

  // FontFaceObserver
  fontFaceObserver: {
    testString: 'null', // null uses default fontfaceobserver test string
    timeout: 3000,
  },
}

TypeTester.propTypes = {
  // Draft
  className: PropTypes.string,
  classNameNamespace: PropTypes.string,
  editorKey: PropTypes.string,
  placeholder: PropTypes.string.isRequired,
  readOnly: PropTypes.bool,
  textAlignment: PropTypes.string,
  value: PropTypes.string.isRequired,

  // TypeTester
  scale: PropTypes.bool,
  scaleOnResize: PropTypes.bool,
  scaleOnEdit: PropTypes.bool,
  scaleOnLineBreak: PropTypes.bool,
  minFontSize: PropTypes.number,
  maxFontSize: PropTypes.number,
  caret: PropTypes.bool,

  // Callbacks
  onClick: PropTypes.func,
  onChange: PropTypes.func,
  onChangeFontSize: PropTypes.func,

  // FontFaceObserver
  fontFaceObserver: PropTypes.shape({
    testString: PropTypes.string,
    timeout: PropTypes.number,
  }).isRequired,

  // Old props
  overflowX: function () {
    return invalidProp(arguments, 'Replace with overflowX on the parent.')
  },
  overflowY: function () {
    return invalidProp(arguments, 'Replace with overflowY on the parent.')
  },
  testString: function () {
    return invalidProp(arguments, 'Replace with `fontFaceObserver.testString`.')
  },
  timeout: function () {
    return invalidProp(arguments, 'Replace with `fontFaceObserver.timeout`.')
  },
  analytics: function () {
    return invalidProp(arguments, 'Replace by passing a function to `onClick`.')
  },
}

export default TypeTester
