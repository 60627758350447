import React from 'react'
import { TypeTester, ControlledTypeTester } from '../../src'
import Slider from '../../src/controls/Slider'

const Case = props => {
  return (
    <div
      style={{
        padding: '1rem',
        outline: '2px dashed silver',
        marginBottom: '1.5rem',
      }}
      {...props}
    />
  )
}

const TestCases = props => {
  return (
    <div>
      <link
        href="https://fonts.googleapis.com/css?family=Literata:400i"
        rel="stylesheet"
      />
      <style>{` body { margin: 0; padding: 1rem; font-family: sans-serif; }`}</style>
      <Case data-cy="test-1">
        <div style={{ fontFamily: 'Literata', fontStyle: 'italic' }}>
          <TypeTester
            scale
            value="The Shinkansen N700A Series Set G13 high speed train"
          />
        </div>
      </Case>
      <Case>
        <p>
          Doesn’t scale back up and down correctly without <code>\n</code>
        </p>
        <ControlledTypeTester
          value="The Shinkansen N700A Series Set G13 high speed train travelling at approximately 300 km/h through Himeji Station."
          controls={{
            fontFamily: {
              label: 'Typeface',
              type: 'select',
              defaultValue: 'Literata',
              options: ['Literata'],
            },
          }}
        />
      </Case>
      <Case>
        <p>
          Works as expected with <code>\n</code> to calculate initial line break
        </p>
        <ControlledTypeTester
          value={`The Shinkansen N700A Series Set G13\nhigh speed train travelling at approximately 300 km/h through Himeji Station.`}
          controls={{
            fontFamily: {
              label: 'Typeface',
              type: 'select',
              defaultValue: 'Literata',
              options: ['Literata'],
            },
          }}
        />
      </Case>
    </div>
  )
}

export default TestCases
