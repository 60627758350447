// Live development version for working on the library
// Normally you would do:
// import { TypeTester } from 'typor'
import { TypeTester } from '../../src'
import React from 'react'
import PropTypes from 'prop-types'



const MultiLineViewportUnits = props => {
  return (
    <div
      style={{
        outline: '2px solid white',
      }}>
      <p>Multi-line, maybe better using vw for font sizes?</p>
      <p>
        <del>Next step would be to support this + still be able to calculate the
        “fixed” current font size for type tester controls.</del>
        Done! <code>state.fontSize</code> still has the calculated, fixed value,
        which is still changing when the screen size / component parent changes size,
        but the actual CSS <code>font-size</code> is being set using viewport units.
      </p>
      <p>Maybe add a <code>maxLineLength</code> prop? This would be in situations where
      want the content to wrap, but you also want the text to be as large as possible. How
      do we know where to wrap the phrase? Otherwise it will all end up on one phrase, as is
      currently the default. Alternatively, could allow a single line break, but then you’d
      probably also expect that line break to end up in the tester content.
      Ex. <code>This is my<span style={{ color: 'cornflowerblue' }}>\n</span>sentence to wrap</code>
      </p>

      <TypeTester scale testViewportUnits value="Wondering" />

      <div
        style={{ width: '80vw', margin: '0 auto', outline: '2px dashed red' }}>
        <TypeTester scale value="Wondering" />

        <div style={{ fontSize: '15.208333333333336vw' }}>Wondering</div>
      </div>

      <div
        style={{ width: '80vw', margin: '0 auto', outline: '2px dashed red' }}>
        <code>testViewportUnits</code>
        <TypeTester scale testViewportUnits value="Wondering" />
      </div>
    </div>
  )
}

export default MultiLineViewportUnits
