const getComputedStyle = function(el) {
  if (window) {
    return window.getComputedStyle(el)
  }
}

const getComputedDimensions = function(el) {
  let elStyle = getComputedStyle(el)
  return {
    width: parseFloat(elStyle.getPropertyValue('width'), 10),
    height: parseFloat(elStyle.getPropertyValue('height'), 10),
  }
}

export { getComputedStyle, getComputedDimensions }
